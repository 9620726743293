import React from 'react'
import Helmet from 'react-helmet'
import { Link }         from 'gatsby'

import config from '../../data/site-config'
import Layout from '../components/layout'

const ThanksPage = () => (
  <Layout className="bg-light-blue">
    <Helmet
      title={`📬 Thanks for the mail • ${config.siteTitle}`}
      meta={[
        { name: 'description', content: 'Spacetime provides professional web based services such as design, ux, ui, WordPress development and hosting, seo, ecommerce, Shopify, Kickstarter' },
        { name: 'robots', content: 'noindex'},
      ]}
    />

    <section id="PageHero" className="page-hero">

      <div className="page-hero__content row">
        <h1 className="t-headline">Thank You!</h1>
        <p className="f3 fw4 lh-copy white o-50 measure-narrow">We&rsquo;ve received your message and will get back to you in one hot minute! Until then&hellip;</p>
        <Link
          to="/portfolio/"
          className="f6 fw5 link br1 ph3 pv3 mt4 dib bg-white black dim">
            Check out our portfolio &rarr;
        </Link>

      </div>

    </section>

  </Layout>
)

export default ThanksPage
